import React from "react";
import { graphql } from "gatsby";
import get from "lodash/get";

import Layout from "../components/layout";

const Author = (props) => {
  const [authorEdge] = get(props, "data.allContentfulPerson.edges");
  const author = authorEdge.node;
  const twitterUrl = author.twitter
    ? `https://twitter.com/${author.twitter.replace(/^@/, ``)}`
    : null;
  const facebookUrl = author.facebook
    ? `https://www.facebook.com/${author.facebook.replace(/^\//, ``)}`
    : null;

  return (
    <>
      {/* <MetaData data={data} location={location} type="profile" /> */}
      <Layout>
        <div className="container">
          <header className="author-header">
            <div
              className="author-header-image"
              //   style={{ width: 250, height: 250 }}
            >
              {author.heroImage.fluid && (
                <img src="/author.jpg" alt={author.name} />
              )}
            </div>
            <div className="author-header-content">
              {author.shortBio.shortBio && <p>{author.shortBio.shortBio}</p>}
              <div className="author-header-meta">
                {author.website && (
                  <a
                    className="author-header-item"
                    href={author.website}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Website
                  </a>
                )}
                {twitterUrl && (
                  <a
                    className="author-header-item"
                    href={twitterUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Twitter
                  </a>
                )}
                {facebookUrl && (
                  <a
                    className="author-header-item"
                    href={facebookUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Facebook
                  </a>
                )}
              </div>
            </div>
          </header>
        </div>
      </Layout>
    </>
  );
};

export default Author;

export const pageQuery = graphql`
  query AuthorQuery {
    allContentfulPerson(
      filter: { contentful_id: { eq: "15jwOBqpxqSAOy2eOO4S0m" } }
    ) {
      edges {
        node {
          name
          shortBio {
            shortBio
          }
          title
          heroImage: image {
            fluid(
              maxWidth: 1180
              maxHeight: 480
              resizingBehavior: PAD
              background: "rgb:000000"
            ) {
              ...GatsbyContentfulFluid_tracedSVG
            }
          }
        }
      }
    }
  }
`;
